import React from "react";
import { AnimatedSocialIcon } from 'react-animated-social-icons';


class Team extends React.Component {
    render() {
        return (

            <div>
                <div className="team" id="team">
                    <div className="section-heading" data-aos="fade-up">
                        <h1>
                            Our Team
                        </h1>
                    </div>
                    <div className="team-members">
                        <div className="team-member">
                            <div className="left">
                                <img src="assets/images/201018-eagle_bird-min.webp" data-aos="zoom-in" alt=""></img>
                            </div>
                            <div className="right" data-aos="zoom-out-up">
                                <h1 className="team-member-name">Kenneth Byrd – Head Linksman / Lead Investor</h1>
                                <p className="member-details">
                                    Kenneth Byrd has successfully practiced law for over 20 years. He joined the crypto space in 2017, 
                                    working full-time developing DAO’s, setting up stake pools, building mining rigs, and enjoying everything crypto has to offer.
                                    When not on the golf course trying to lower his handicap, he can be found online and in his office tinkering with the next big thing.
                                </p>
                                <center>
                                    <div className="member-social">
                                        <AnimatedSocialIcon
                                            brandName="linkedin"
                                            width="1.5em"
                                            url="https://www.linkedin.com/in/kenneth-byrd/"
                                            defaultColor="#D1D1D1"
                                            hoverColor="black"
                                            animation="bounce"
                                            animationDuration={0.4} />

                                        <AnimatedSocialIcon
                                            brandName="twitter"
                                            width="1.5em"
                                            url="https://twitter.com/Byrdcoin1"
                                            defaultColor="#D1D1D1"
                                            hoverColor="black"
                                            animation="bounce"
                                            animationDuration={0.4} />
                                    </div>
                                </center>
                            </div>
                        </div>

                        <div className="team-member">
                            <div className="left">
                                <img src="assets/images/Golf Byrds_1.png" data-aos="zoom-in" alt=""></img>
                            </div>
                            <div className="right" data-aos="zoom-out-up">
                                <h1 className="team-member-name">Anthony Sandoval – Executive Officer</h1>
                                <p className="member-details">
                                Anthony Sandoval has managed a small personal portfolio of Real Estate assets for 19 years.  Former Vice-President of a Multinational Fashion Brand. Fascinated with Tokenizing Real Estate. In crypto since 2014. "NFTs and DAOs will permit us to interact with the world in unprecedented ways, creating fairer societies and more efficient management". Founding member of the <a href="https://www.instagram.com/tulumcryptoclub/" target="_blank" rel="noopener noreferrer">Tulum Crypto Club</a></p>
                                <center>
                                    <div className="member-social">
                                        <AnimatedSocialIcon
                                            brandName="linkedin"
                                            width="1.5em"
                                            url="https://www.linkedin.com/in/anthony-h-sandoval/"
                                            defaultColor="#D1D1D1"
                                            hoverColor="black"
                                            animation="bounce"
                                            animationDuration={0.4} />

                                        <AnimatedSocialIcon
                                            brandName="twitter"
                                            width="1.5em"
                                            url="https://twitter.com/Magnus_A_Legend"
                                            defaultColor="#D1D1D1"
                                            hoverColor="black"
                                            animation="bounce"
                                            animationDuration={0.4} />
                                    </div>
                                </center>
                            </div>
                        </div>


                        <div className="team-member">
                            <div className="left">
                                <img src="assets/images/221004-Turkey_Bird-min.webp" data-aos="zoom-in" alt=""></img>
                            </div>
                            <div className="right" data-aos="zoom-out-up">
                                <h1 className="team-member-name">David A. Divas – Tech Lead/Smart Contract Developer</h1>
                                <p className="member-details">
                                    "Before the raging storm secedes the deep blue will heed the helm of fortune, swaying ever so closely to rest. The calm before the storm.
                                    Branching trails of destiny that forlorn the great mountain of humanity. Where shall we head as a collective network of souls?
                                    This fire is what lights the passions of our soul to go forth and bring creation into this world for the betterment of our symbiotic relationship with the beautiful planet that is Earth." <br></br> <br></br> 
                                David Divas has worked as a computer programmer in cybersecurity for major tech companies such as Google. He has an extensive knowledge of Solidity and other Crypto programming languages.
                                </p>

                                <center>
                                    <div className="member-social">
                                        <AnimatedSocialIcon
                                            brandName="github"
                                            width="1.5em"
                                            url="https://art.chigag.studio/"
                                            defaultColor="#D1D1D1"
                                            hoverColor="black"
                                            animation="bounce"
                                            animationDuration={0.4} />
                                    </div>



                                </center>
                            </div>
                        </div>
                        <div className="team-member">
                            <div className="left">
                                <img src="assets/images/201018-eagle_bird-min.webp" data-aos="zoom-in" alt=""></img>
                            </div>
                            <div className="right" data-aos="zoom-out-up">
                                <h1 className="team-member-name">Taino ('LyghtCode') – Web3 Developer</h1>
                                <p className="member-details">
                                "Yes, we're in the Matrix. In Lak’ech Ala K’in. 01001100 01100101 00100000 01100100 01101111 01111001 00100000 01100111 01110010 01100001 01100011 01101001 01100001 01110011 00100000 01100001 00100000 01000100 01101001 01101111 01110011 00001010 01010000 01101111 01110010 00100000 01101000 01101111 01111001 00100000 01100101 01110011 01110100 01100001 01110010 00100000 01100100 01101111 01101110 01100100 01100101 00100000 01100101 01110011 01110100 01101111 01111001". <br></br> <br></br>  Taino is an experienced Web3 developer fully proficient in Typescript, Javascript, Python, Solidity and HTML.
                                  </p>
                                <center>
                                    <div className="member-social">

                                    <AnimatedSocialIcon
                                            brandName="github"
                                            width="1.5em"
                                            url="https://github.com/LyghtCode"
                                            defaultColor="#D1D1D1"
                                            hoverColor="black"
                                            animation="bounce"
                                            animationDuration={0.4} />

                                     {/*   <AnimatedSocialIcon
                                            brandName="twitter"
                                            width="1.5em"
                                            url="https://twitter.com/TainoMusica"
                                            defaultColor="#D1D1D1"
                                            hoverColor="black"
                                            animation="bounce"
                                            animationDuration={0.4} />    */}
                                            
                                    </div>
                                </center>
                            </div>
                        </div>

                        <div className="team-member">
                            <div className="left">
                                <img src="assets/images/221001-Albatros-min.webp" data-aos="zoom-in" alt=""></img>
                            </div>
                            <div className="right" data-aos="zoom-out-up">
                                <h1 className="team-member-name">Sabrina Stocker - Two Comma PR - PR Team</h1>
                                <p className="member-details">
                                Sabrina Stocker is a tennis player turned Forbes featured entrepreneur. Sabrina has built several successful companies, mentored hundreds of entrepreneurs, and was a finalist in the BBC One Show, The Apprentice. <br></br>  <br></br>

Having spoken in front of thousands at arenas including Wembley Stadium, The Excel Stadium, and the London Olympic Stadium, she has created a loyal community. Her podcast "Be The Authority Show" has featured notable celebrities such as Elena Cardone.  <br></br>  <br></br>

Sabrina is the Founder of <a href="https://www.twocommapr.io/" target="_blank" rel="noopener noreferrer">Two Comma PR</a>, a public relations agency turning the stories of brands and blockchain projects into users. She is also co-founder of Shopping Slot and My Tennis Events, the largest tennis tournament in the UK.</p>
                                <center>
                                    <div className="member-social">

                                    <AnimatedSocialIcon
                                            brandName="linkedin"
                                            width="1.5em"
                                            url="https://sabrinastocker.com/about/"
                                            defaultColor="#D1D1D1"
                                            hoverColor="black"
                                            animation="bounce"
                                            animationDuration={0.4} />

                                        <AnimatedSocialIcon
                                            brandName="twitter"
                                            width="1.5em"
                                            url="https://twitter.com/sabrinaweb3pr"
                                            defaultColor="#D1D1D1"
                                            hoverColor="black"
                                            animation="bounce"
                                            animationDuration={0.4} />
                                            
                                    </div>
                                </center>
                            </div>
                        </div>


              {/*          <div className="team-member">
                            <div className="left">
                                <img src="assets/images/CriptoGringobw.jpg" data-aos="zoom-in" alt=""></img>
                            </div>
                            <div className="right" data-aos="zoom-out-up">
                                <h1 className="team-member-name">CryptoGringo – Albatross Project Marketer</h1>
                                <p className="member-details">
                                    Gringo(Paul) is a Twitter obsessed, meme making, adventure having, in it for the tech, crypto believer.
                                    The share economy is a huge motivator for him and when he heard about the chance to be a member of an exclusive Golf Course, he was all in.
                                    Come find him in the discord and give him your best short game tips.
                                </p>

                                <center>
                                    <div className="member-social">
                                        <AnimatedSocialIcon
                                            brandName="linkedin"
                                            width="1.5em"
                                            url="https://www.linkedin.com/in/paul-middleton-73579b1b/"
                                            defaultColor="#D1D1D1"
                                            hoverColor="black"
                                            animation="bounce"
                                            animationDuration={0.4} />

                                        <AnimatedSocialIcon
                                            brandName="twitter"
                                            width="1.5em"
                                            url="https://twitter.com/bitcoingringo"
                                            defaultColor="#D1D1D1"
                                            hoverColor="black"
                                            animation="bounce"
                                            animationDuration={0.4} />
                                    </div>
                                </center>
                            </div>
                                        </div>       */}

            {/*            <div className="team-member">
                            <div className="left">
                                <img src="assets/images/keith-avatarbw.jpg" data-aos="zoom-in" alt=""></img>
                            </div>
                            <div className="right" data-aos="zoom-out-up">
                                <h1 className="team-member-name">Keith Salins – Eagle Project Marketer
                                </h1>
                                <p className="member-details">
                                    As confirmed by influential Web3 space figures: “Builder of the best communities in Web3”.
                                    Will create 12 tech businesses this year. Currently focused on making our Discord and our Branding amazing. </p>

                                <center>
                                    <div className="member-social">
                                        <AnimatedSocialIcon
                                            brandName="linkedin"
                                            width="1.5em"
                                            url="https://www.linkedin.com/in/keithsalins/"
                                            defaultColor="#D1D1D1"
                                            hoverColor="black"
                                            animation="bounce"
                                            animationDuration={0.4} />



                                        <AnimatedSocialIcon
                                            brandName="twitter"
                                            width="1.5em"
                                            url="https://twitter.com/kthsalins"
                                            defaultColor="#D1D1D1"
                                            hoverColor="black"
                                            animation="bounce"
                                            animationDuration={0.4} />
                                    </div>
                                </center>
                            </div>
                        </div>    */}
                    </div>
                </div>
            </div>
        );
    }
}

export default Team;
