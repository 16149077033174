import React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import LazyHero from "react-lazy-hero";


class ParallaxOne extends React.Component {
    render() {
        return (
            <div>


                <div className="section-heading" data-aos="fade-up">
                    <h1>Mint The GolfDAO Membership NFT</h1>
                </div>
                <LazyHero imageSrc="assets/images/wstill.jpeg" opacity='0' minHeight="333px" parallaxOffset='99'>
                </LazyHero>
 



            </div>
        );
    }
}

export default ParallaxOne;
