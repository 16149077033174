import React from "react";
import { shortenAddress } from "../utils/constant";
import { Button } from "react-bootstrap";

const Nav = () => {

    return (
        <div>

            <nav className="navbar" id="navbar">
            <div className="left">
                    <a href="/"><img alt="" src="assets/images/isotypicontr.webp" width={80}></img></a>
                   {/* <a href="/"><img alt="" src="assets/images/letrascut.webp" width={200}></img></a> */}
                </div>
            <div className="menu">
                    <i onClick={window['alertHello']} className="fas fa-bars"></i>
                </div>
               

                

                <div className="right">
                  {/*  <a href="https://market.thegolfdao.io/" target="_blank" rel="noopener noreferrer">Clubhouse</a>   */} 

                    <a href="https://snapshot.org/#/golf-dao.eth" target="_blank" rel="noopener noreferrer">Vote</a>
                    <a href="/#roadmap">Roadmap</a>
                    <a href="https://pineridgegolfclub.net/" target="_blank" rel="noopener noreferrer">Course</a>
                    <a href="/#team">Team</a>
                    <a href="/#press">Press</a>
                    <a href="/#faq">FAQ</a>
                    {/* <a style={{ color: window.location.pathname === "/" ? "white" : "" }} href="/">Home</a> */}

                    {/* <a style={{ color: window.location.pathname === "/golf-course" ? "white" : "" }} href="/#/golf-course">Golf Course</a>
                    <a style={{ color: window.location.pathname === "/how-to-buy" ? "white" : "" }} href="/#/how-to-buy">How to buy</a> */}

                    {
                    <Button onClick={() => console.log("wazaahhhh")} ><a href="https://market.thegolfdao.io/mint"  className="btn-main" target="_blank" rel="noopener noreferrer">Open DApp</a></Button>
                    }
                    {/* <Button className="btn">{shortenAddress(currentAccount)}</Button> : <Button className="btn" onClick={() => connectWallet()} >Connect</Button> */}
                </div>
            </nav>
        </div>
    );
}

export default Nav;
