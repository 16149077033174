import React from "react";

class LegalNotice extends React.Component {
    render() {
        return (

            // <!-- footer section starts from here -->
            <div class="plan" data-aos="fade-up">

                <p>Last Modified: March 10, 2022</p>



                <p className="p-gap">TheGolfDAO (<strong>“We”</strong>&nbsp;or&nbsp;<strong>“DAO”&nbsp;</strong>) exists to establish and operate a golf and leisure club. The GolfDAO plans to utilize cryptographically secure blockchain organization management tools to automate and improve transparency around certain aspects of organizational governance of the club’s business, commonly known as a “decentralized autonomous organization” or a “DAO.”</p>



                <p className="p-gap">We are offering for sale two types of digital collectibles (each a “Membership NFT”) on or about March 15, 2022: a “leisure membership” NFT (“Leisure NFT”) and Global Membership NFT (“Global Membership”). The purchase of each NFT is a transaction in goods and not a promise to provide, or a guarantee of receipt of, future services from TheGolfDAO , although TheGolfDAO will make reasonable efforts to ensure that a DAO comes into existence which allows you to unlock expanded functionality associated with the Membership NFTs. Right to membership in the golf and leisure club will be subject to club rules, the terms of which will be determined if and when the club comes into existence in coordination with the DAO.</p>



                <p className="p-gap">You understand and agree that the sale of Membership NFTs grants you no rights and carries with it no guarantee of future performance of any kind by TheGolfDAO You are not entitled, as a holder of any Membership NFT, to vote or receive dividends or profits or be deemed the holder of shares of TheGolfDAO or any other person by virtue of your ownership of a Membership NFT, nor will anything contained herein be construed to construe on you any of the rights of a shareholder, partner, joint venturer, etc. or any right to vote for the election of directors or upon any matter submitted to shareholders at any meeting thereof, or to give or withhold consent to any corporate action or to receive notice of meetings, or to receive subscription rights to purchase such shares of TheGolfDAO.&nbsp; TheGolfDAO may in future offer holders of Membership NFTs the opportunity to participate in massively open online governance via DAO. You agree that the functionality of any DAO established by TheGolfDAO will be determined by TheGolfDAO in its sole and absolute discretion.</p>



                <p className="p-gap">You understand that the development of TheGolfDAO’s business is a significantly risky venture and you acknowledge and assume the risk that TheGolfDAO may not be able to complete, substantially or in part, the acquisition of a golf course or any other aspect of its operations and furthermore that a significant proportion of the funds generated by the Membership NFT drop are expected to be retained by TheGolfDAO, for any lawful business purpose. Your purchase of an NFT could lead to the complete loss of your money, and no payments will be returned. Membership NFTs will be created and delivered to any purchaser at the sole risk of the purchaser and on an “as-is” basis. Purchaser has not relied on any representations or warranties made by TheGolfDAO or any other person outside of the context of these Terms of Sale, including but not limited to conversations of any kind, including but not limited to whether such statements are made through oral or electronic communication, or any White Paper, social media post or website.</p>



                <p className="p-gap">The sale of the Membership NFT is governed by, and these Terms of Sale will be construed in accordance with, Oregon law, without regard to conflict of laws principles. All disputes with TheGolfDAO by you or your successors or assigns directly or indirectly arising under or in relation to these Terms of Sale or the NFTS shall be brought and resolved solely and exclusively in final and binding arbitration in the State of Oregon under the Rules of Arbitration of the American Arbitration Association.</p>



                <p className="p-gap">CLASS ACTION WAIVER: TO THE EXTENT PERMISSIBLE BY LAW, ALL CLAIMS MUST BE BROUGHT IN A PARTY’S INDIVIDUAL CAPACITY, AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS, COLLECTIVE ACTION, OR REPRESENTATIVE PROCEEDING. THE ARBITRATOR MAY NOT CONSOLIDATE MORE THAN ONE PERSON’S CLAIMS OR ENGAGE IN ANY CLASS ARBITRATION. YOU ACKNOWLEDGE THAT, BY AGREEING TO THESE TERMS, YOU AND TheGolfDAO ARE EACH WAIVING THE RIGHT TO A TRIAL BY A JUDGE OR A JURY IN A PUBLIC FORUM AND THE RIGHT TO PARTICIPATE IN A CLASS ACTION.</p>



                <p className="p-gap">Without prejudice to any other provision of these Terms of Sale, you acknowledge and agree that, except in the case of TheGolfDAO’s fraud or wilful default, and except as otherwise required by any non-waivable provision of applicable law, TheGolfDAO shall not be liable in any manner whatsoever to you or any other person for losses or damages of any kind, whether arising in contract, tort, or otherwise, arising from the sale of Membership NFTs to any person.</p>



                <p className="p-gap"><strong>All purchases of any Membership NFT are final and nonrefundable.</strong></p>



                <p className="p-gap">If you have any questions please contact TheGolfDAO at contact@ TheGolfDAO.io</p>



                <p><a href="https://thegolfdao.io/privacy-policy/">Privacy Policy</a></p>
            </div>
            // <!-- footer section ends here -->

        );
    }
}

export default LegalNotice;