import React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import LottieDisplay from "../component/lottie";

class Course extends React.Component {
    render() {
        return (
            <div className="course" data-aos="fade-up">

          

             {/*  <LottieDisplay animationPath="/lottie/golfer.json" height={300} width={300} />   */}

                <img src="assets/images/golf-ace.webp" width={400} data-aos="zoom-in" alt=""></img>
                    
                
                <h1 className="heading2">Golf Course Developments</h1>


               

                <p className="p-gap">We're buying a Golf Course in the USA. Details of the purchasing agreement and golf course earnings can be requested <a href="mailto:info@thegolfdao.io" target="_blank" rel="noopener noreferrer"> here</a>.
                </p>
                <p className="p-gap"> A complete 18-hole golf facility with covered driving range, putting green,
                    and short game practice area.</p>
                <p className="p-gap">Prime real estate opportunities. Extra land to develop as residential.</p>


              {/*  <LottieDisplay animationPath="/lottie/regen.json" height={300} width={300} />   */}

                <img src="assets/images/golf-field.webp" width={400} data-aos="zoom-in" alt=""></img>

                <h1>Regenerative</h1>

                {/*<p className="p-gap">We have consultants from regenerative golf courses in Ireland and Scotland.</p> */}
                <p className="p-gap">We will steward the land in an environmentally friendly way. Best practices in our fields:
                    Solar energy, wind.</p>
                <p className="p-gap"> Water management, healthy soils and budding natural ecosystems.</p>




                {/*               <div className="iframe">
                    <iframe title="maps" className="map"
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d26360969.983003654!2d-113.74991983034641!3d36.24212122031438!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x54eab584e432360b%3A0x1c3bb99243deb742!2sUnited%20States!5e0!3m2!1sen!2smx!4v1661298304596!5m2!1sen!2smx"
                        loading="lazy"
                    ></iframe></div>
                <br />                */}


                {/*           <center><p>Check out more images on our <a href="https://www.instagram.com/golfdao1" target="_blank" rel="noopener noreferrer"> INSTAGRAM  </a> page.
                </p></center>     */}


                <div>
                    {/* <Swiper 
                    effect={"cards"}
                        grabCursor={true}
                        navigation={true}
                        modules={[EffectCards, Navigation]}
                        className="mySwiper"
                         >
                        <SwiperSlide><img src='assets/images/golf/IMG_8418-768x576.jpg'></img></SwiperSlide>
                        <SwiperSlide><img src='assets/images/golf/IMG_8419-768x576.jpg'></img></SwiperSlide>
                        <SwiperSlide><img src='assets/images/golf/IMG_8421-768x576.jpg'></img></SwiperSlide>
                        <SwiperSlide><img src='assets/images/golf/IMG_8422-768x576.jpg'></img></SwiperSlide>
                        <SwiperSlide><img src='assets/images/golf/IMG_8430-768x576.jpg'></img></SwiperSlide>
                        <SwiperSlide><img src='assets/images/golf/IMG_8431-768x576.jpg'></img></SwiperSlide>
                        <SwiperSlide><img src='assets/images/golf/IMG_8432-768x576.jpg'></img></SwiperSlide>
                        <SwiperSlide><img src='assets/images/golf/IMG_8435-768x576.jpg'></img></SwiperSlide>
                        <SwiperSlide><img src='assets/images/golf/IMG_8439-768x576.jpg'></img></SwiperSlide>
                        <SwiperSlide><img src='assets/images/golf/IMG_8441-768x576.jpg'></img></SwiperSlide>
                        <SwiperSlide><img src='assets/images/golf/IMG_8450-768x576.jpg'></img></SwiperSlide>
                        <SwiperSlide><img src='assets/images/golf/IMG_8451-768x576.jpg'></img></SwiperSlide>
                        <SwiperSlide><img src='assets/images/golf/IMG_8458-768x576.jpg'></img></SwiperSlide>
                        <SwiperSlide><img src='assets/images/golf/IMG_8465-768x576.jpg'></img></SwiperSlide>
                    </Swiper> */}

                </div>

            </div>
        );
    }
}

export default Course;
