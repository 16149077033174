import React from "react";


const Press = () => {
    return (
        <div>
            {/* header section starts from here */}

            <div className="press" id="press">
                <div className="right" data-aos="fade-right">
                    <h1>Apple News<br/></h1>
                    <a href="https://apple.news/Aun5Ua1JKNJilrdllkes7yQ" target="_blank" rel="noopener noreferrer"><img src="assets/images/apple-news2.png" alt="golfdao"></img></a>
                </div>

            </div>
         {/*   <div className="press">
                <div className="right" data-aos="fade-left">
                    <h1> Medium <br /> </h1>
                    <a href="https://medium.com/@anthonysenmusic/the-importance-of-proving-that-daos-work-935242125416" target="_blank" rel="noopener noreferrer"><img src="assets/images/medium.png" alt="golfdao"></img></a>
                </div>

    </div> */}

            <div className="press">
                <div className="right" data-aos="fade-right">
                    <h1> CryptoPress <br /> </h1>
                    <a href="https://cryptopress.site/crypto/crypto-is-shattering-the-house-of-cards-in-finance/" target="_blank" rel="noopener noreferrer"><img src="assets/images/newspaper.webp" alt="golfdao"></img></a>
                </div>

            </div>


            <div className="press">
                <div className="right" data-aos="fade-left">
                    <h1> TechBullion <br /> </h1>
                    <a href="https://techbullion.com/why-the-future-of-real-estate-will-be-revolutionary/" target="_blank" rel="noopener noreferrer"><img src="assets/images/medium.png" alt="golfdao"></img></a>
                </div>

            </div>

            
            {/* header section ends here */}
        </div>
    );
}

export default Press;
