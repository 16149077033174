import React from "react";
import LazyHero from "react-lazy-hero";


class Faq extends React.Component {
    render() {
        return (
            <div>
                <div className="join" data-aos="fade-up">
            
                    <h1>
                        Join the Caddy Shack
                    </h1>
            
   
            <LazyHero imageSrc="assets/images/panel.jpg" opacity=".0" minHeight="1000px" parallaxOffset='66'>

            </LazyHero>
            

                    <p className="section-heading">
                        Our Discord community aims to attract golfers and sports enthusiasts of all levels – from celebrity crypto golfers to golf newcomers. Updates and links on Discord will keep you informed on DAO activity. A space to meet other members from the Golf Dao community.
                        If you have purchased memberships you have voting rights over the treasury and governance rights over the direction we will take in the future. We consider you active members with a voice in what we do and how we do it.  Make yourself heard!
                    </p>
                    <a href="https://discord.gg/sxp3QxpnTU" target="_blank" rel="noopener noreferrer" className="btn"> <i className="fa-brands fa-discord"></i>   Join Discord</a>
                </div>
                <div className="faq" id="faq">
                    <div className="section-heading" data-aos="fade-up">
                        <h1>
                            Frequently Asked Questions
                        </h1>
                    </div>

                    <div className="faq-dta">
                    <details>
                            <summary data-aos="zoom-out-up">How can Non-accredited investors participate in our DAO?</summary>
                            <div className="faq__content">
                                <p>Non accredited US based investors will be subject to holding a maximum of 2 GolfDAO standard birdie membership NFTs.</p>
                                <p>All investors interested in participating in our project will be subject to KYC/AML procedures to be whitelisted. All investors must be over 18 years old to participate.</p>
                                <p>Non accredited non-US investors will be able to purchase standard birdie membership NFTs at their discression.</p>
                                <p>GolfBYRD NFTs available only to Accredited Investors and non-US investors.</p>
                                <br></br>
                            </div>
                        </details>
                        <details>
                            <summary data-aos="zoom-out-up">What is an NFT?</summary>
                            <div className="faq__content">
                                <p>NFT = “Non-fungible token”. A one-of-a-kind digital item that users can buy, own, and trade. Some
                                    NFTs main functions are to look amazing, some offer additional uses. Our Golf Dao NFT will act
                                    as a “members” card which gets you access to members-only perks.</p>
                            </div>
                        </details>
                        <details>
                            <summary data-aos="zoom-out-up">What will the NFT Membership Card sale proceeds be used for?</summary>
                            <div className="faq__content">
                                <p> The proceeds, net of taxes, will be used to fund DAO operations, including, but not limited to…

                                </p>
                                <ul >
                                    <li>Full purchase of a Golf Course in the USA</li>
                                    <li>Operational leadership, day-to day operations</li>
                                    <li>Financial and acquisition planning</li>
                                    <li>Product Engineering</li>
                                    <li>DAO infrastructure setup and fees</li>
                                    <li>Legal, compliance and accounting</li>
                                    <li>Marketing, partnerships and sales</li>
                                    <li>Community development</li>
                                    <li>Further initiatives as approved by the DAO</li> <br />
                                </ul>
                            </div>
                        </details>
                        <details>
                            <summary>What governance rights will GolfDAO Membership Card holders have?</summary>
                            <div className="faq__content" data-aos="zoom-in-up">
                                <p>Members of the GolfDAO NFT membership card and GolfBYRD holders shall have the following rights:
                                </p>
                                <ul >
                                    <li> Approval of GolfDAO functional operating committees </li>
                                    <li> Physical club selection process and approval, as proposed by the relevant operating committee </li>
                                    <li> Club rules, including culture, activities, design, and overall vision </li>
                                    <li> Club membership rules and admissions policies </li>
                                    <li> Marketing, partnerships and sales </li><br />
                                </ul>
                            </div>
                        </details>
                        <details>
                            <summary>Who do you share your data with?</summary>
                            <div className="faq__content" data-aos="zoom-in-up">
                                <p>
                                    Visitor data may be checked through an automated spam detection service.
                                </p>
                                <p><strong>What about data collected from embedded sources? (ie. OnRamper Fiat Gateway)</strong></p>
                                <p>Articles on this site may include embedded content (e.g. videos, images, articles, etc.). Embedded content from other websites behaves in the exact same way as if the visitor has visited the other website.
                                    These websites may collect data about you, use cookies, embed additional third-party tracking, and monitor your interaction with that embedded content, including tracking your interaction with the embedded content if you have an account and are logged in to that website.</p>
                            </div>
                        </details>
                        <details>
                            <summary>What is the legality of purchasing a 'TheGolfDAO' NFT? (Full legal notice)</summary>
                            <div className="faq__content" data-aos="zoom-in-up">
                                <p>TheGolfDAO (<strong>“We”</strong>&nbsp;or&nbsp;<strong>“DAO”&nbsp;</strong>) exists to establish and operate a golf and leisure club. <br /><br /> The GolfDAO plans to utilize cryptographically secure blockchain organization management tools to automate and improve transparency around certain aspects of organizational governance of the club’s business, commonly known as a “decentralized autonomous organization” or a “DAO.” <br /><br /> We are offering for sale two types of digital collectibles (each a “Membership NFT”) on or about December 1, 2022: a “general membership” NFT (“Birdie NFT”) and GolfBYRD Membership NFT (“GolfBYRD Membership”). <br /><br /> The purchase of each NFT is a transaction in goods and not a promise to provide, or a guarantee of receipt of, future services from TheGolfDAO, although TheGolfDAO will make reasonable efforts to ensure that a DAO comes into existence which allows you to unlock expanded functionality associated with the Membership NFTs. <br /><br /> Right to membership in the golf and leisure club will be subject to club rules, the terms of which will be determined if and when the club comes into existence in coordination with the DAO. <br /><br />
                                    You understand and agree that the purchase of General Membership NFTs grants you no rights and carries with it no guarantee of future performance of any kind by TheGolfDAO. You are not entitled, as a holder of a General Membership NFT, to vote or receive dividends or profits or be deemed the holder of shares of TheGolfDAO or any other person by virtue of your ownership of a Membership NFT, nor will anything contained herein be construed to construe on you any of the rights of a shareholder, partner, joint venturer, etc. or any right to vote for the election of directors or upon any matter submitted to shareholders at any meeting thereof, or to give or withhold consent to any corporate action or to receive notice of meetings, or to receive subscription rights to purchase such shares of TheGolfDAO.&nbsp; <br /><br /> TheGolfDAO may in future offer holders of Membership NFTs the opportunity to participate in massively open online governance via DAO. You agree that the functionality of any DAO established by TheGolfDAO will be determined by TheGolfDAO in its sole and absolute discretion.
                                    You understand that the development of TheGolfDAO’s business is a significantly risky venture and you acknowledge and assume the risk that TheGolfDAO may not be able to complete, substantially or in part, the acquisition of a golf course or any other aspect of its operations and furthermore that a significant proportion of the funds generated by the Membership NFT drop are expected to be retained by TheGolfDAO, for any lawful business purpose. Your purchase of an NFT could lead to the complete loss of your money, and no payments will be returned. Membership NFTs will be created and delivered to any purchaser at the sole risk of the purchaser and on an “as-is” basis. Purchaser has not relied on any representations or warranties made by TheGolfDAO or any other person outside of the context of these Terms of Sale, including but not limited to conversations of any kind, including but not limited to whether such statements are made through oral or electronic communication, or any White Paper, social media post or website. <br /><br />
                                    The sale of the Membership NFT is governed by, and these Terms of Sale will be construed in accordance with, Oregon law, without regard to conflict of laws principles. All disputes with TheGolfDAO by you or your successors or assigns directly or indirectly arising under or in relation to these Terms of Sale or the NFTS shall be brought and resolved solely and exclusively in final and binding arbitration in the State of Oregon under the Rules of Arbitration of the American Arbitration Association.
                                    CLASS ACTION WAIVER: TO THE EXTENT PERMISSIBLE BY LAW, ALL CLAIMS MUST BE BROUGHT IN A PARTY’S INDIVIDUAL CAPACITY, AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS, COLLECTIVE ACTION, OR REPRESENTATIVE PROCEEDING. THE ARBITRATOR MAY NOT CONSOLIDATE MORE THAN ONE PERSON’S CLAIMS OR ENGAGE IN ANY CLASS ARBITRATION. YOU ACKNOWLEDGE THAT, BY AGREEING TO THESE TERMS, YOU AND TheGolfDAO ARE EACH WAIVING THE RIGHT TO A TRIAL BY A JUDGE OR A JURY IN A PUBLIC FORUM AND THE RIGHT TO PARTICIPATE IN A CLASS ACTION.
                                    Without prejudice to any other provision of these Terms of Sale, you acknowledge and agree that, except in the case of TheGolfDAO’s fraud or wilful default, and except as otherwise required by any non-waivable provision of applicable law, TheGolfDAO shall not be liable in any manner whatsoever to you or any other person for losses or damages of any kind, whether arising in contract, tort, or otherwise, arising from the sale of Membership NFTs to any person.
                                    <strong>All purchases of any Membership NFT are final and non-refundable.</strong> <br /><br /> Accredited investors interested in participating in our GolfBYRDS profit sharing program, as well as general members that wish to actively participate in our DAO, understand that they will be subject to further KYC ("Know Your Client") procedures in compliance with USA SEC regulations. 
                                </p>
                            </div>
                        </details>
                        {/* <!-- <details>
                <summary data-aos="zoom-out-up">What is the 180 SOL post mint giveaway?</summary>
                <div className="faq__content" data-aos="zoom-in-up">
                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Officia, non asperiores natus quasi quae
                        nemo, voluptatem, explicabo eius vitae repellendus itaque minus.</p>
                </div>
            </details> -->
            <!-- <details>
                <summary data-aos="zoom-out-up">What if I don’t win?</summary>
                <div className="faq__content" data-aos="zoom-in-up">
                    <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis, quisquam, ex optio unde
                        corrupti deleniti voluptas facere voluptatum consequuntur enim iste, ut fugit sequi nesciunt
                        asperiores at quae! Accusantium hic soluta ut
                        impedit, perferendis, minima, vel quo maiores reiciendis recusandae enim tempora earum atque!
                    </p>
                </div>
            </details>
            <details>
                <summary data-aos="zoom-out-up">What if I want out?</summary>
                <div className="faq__content" data-aos="zoom-in-up">
                    <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Repellat a iusto facilis eaque optio at
                        eum. Nostrum quibusdam ad quaerat vero molestias!</p>
                </div>
            </details> -->
            <!-- <details>
                <summary data-aos="zoom-out-up">What will happen to your NFT bought by the treasury wallet?</summary>
                <div className="faq__content" data-aos="zoom-in-up">
                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Possimus amet harum animi architecto
                        itaque, id doloremque saepe, odio consequuntur, maxime porro aliquid? Vero voluptas reiciendis
                        animi error voluptates repellat adipisci porro
                        eligendi aspernatur.</p>
                </div>
            </details> --> */}
                    </div>
                </div>
            </div>
        );
    }
}

export default Faq;
