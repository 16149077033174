import React from "react";
import LazyHero from "react-lazy-hero";
import LottieDisplay from "../component/lottie";


const Header = () => {
    return (
        <div>
            {/* header section starts from here */}
            <LazyHero imageSrc="assets/images/golfdao_banner-1.webp" opacity="0" minHeight="1080px" parallaxOffset='99'>
            </LazyHero>

            <div id="home">
                <center>
                    <br></br><br /><br />
                    <br></br><br /><br />

                    <font size="+5"> <h1>Golf  Crypto  (Real) Real Estate</h1>  </font>

                    <br></br> <br></br>
                    <br></br> <br></br>

                    <img src="assets/images/spmap.jpg" width={400} data-aos="zoom-in" alt=""></img>

                    <br></br> <br></br>


                    {/*  <LottieDisplay animationPath="/lottie/golf.json" height={200} width={300} /> */}
                    <img src="assets/images/ByrdsPreview.jpg" width={400} data-aos="zoom-in" alt=""></img>

                    <br></br><br /><br />
                    <br></br><br /><br />



                    <br></br><br /><br />
                    <br></br><br /><br />


                    <center> <a href="https://market.thegolfdao.io/" className="btn-main" target="_blank" rel="noopener noreferrer">
                        JOIN US                </a>  </center>
                    <br></br><br /><br />

                </center>


            </div>
            {/* header section ends here */}
        </div>
    );
}

export default Header;
