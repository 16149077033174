import {
  BrowserRouter as Router,
  Route,
  Routes
} from 'react-router-dom'

import Header from './component/header'
import Mint from './component/mint';
import Footer from './component/footer'
import Roadmap from './component/roadmap'
import Team from './component/team'
import Instruction from './component/instruction';
import Course from './component/course'
import Faq from './component/faq'
import LegalNotice from './component/legal-notice'
import Plan from './component/the-plan'
import Policy from './component/privacypolicy';
import Nav from './component/nav';
import AOS from "aos";
import React, { useEffect } from "react";
import "aos/dist/aos.css";
import './App.css';
import ParallaxOne from './component/parallax-one';
import ParallaxTwo from './component/parallax-two';
import ParallaxThree from './component/parallax-three';
import Press from './component/press';

function App() {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);
  return (
    <Router>
      <Routes>
        <Route path='/' element={<><Nav />
          <Header />
          <Course />
          <ParallaxOne />
          <Mint />
          <Plan />
          {/* <ParallaxTwo/> */}
          <Roadmap />
          <Team />
          <Press />
          <Faq />
          <ParallaxThree />
          <Footer /></>} exact>
        </Route>
        <Route path='/how-to-buy' element={<> <Nav />
          <Instruction />
          <Footer /></>} exact>
        </Route>
        <Route path='/golf-course' element={
          <>
            <Nav />
            <Course />
            <Footer /></>
        } exact>

        </Route>
        <Route path='/legal-notice' element={
          <>
            <Nav />
            <LegalNotice />
            <Footer />
          </>
        } exact>

        </Route>
        <Route path='/privacy-policy' element={
          <>
            <Nav />
            <Policy />
            <Footer />
          </>
        } exact>

        </Route>
      </Routes>
    </Router>

  );
}

export default App;
