import React from "react";
// import OnramperWidget from "@onramper/widget";



class Instruction extends React.Component {
    render() {
        return (
            // <!-- footer section starts from here -->
            <div className="instruction" data-aos="fade-up">

                <div className="headings">
                    <div className="section-heading" data-aos="fade-up">
                        <h1><a href="/"><img alt="" src="assets/images/golf-white.svg" width={200}></img></a></h1>
                    </div>
                    <h1>
                        Purchase Instructions
                    </h1>
                    <p><em>(For Crypto Newbies)</em></p>
                </div>

                <br /> <br />


                <div className="post-container">
                    <div className="left-image"><a href="https://metamask.io/download/" target="_blank" rel="noreferrer noopener"><img src="https://polygonscan.com/images/svg/brands/metamask.svg" alt="Metamask"></img></a></div>
                    <div className="right-text">
                        <h2><a rel="noreferrer noopener" href="https://metamask.io/download/" target="_blank">Crypto Wallet</a></h2>
                        <p>To purchase one of our NFTs and join the DAO you must have a <a rel="noreferrer noopener" href="https://metamask.io/download/" target="_blank">METAMASK</a></p>
                    </div>
                </div>

                <br />

                <div className="post-container">
                    <div className="right-image"><a href="https://polygonscan.com/" target="_blank" rel="noreferrer noopener"><img src="https://res.cloudinary.com/polygontech/image/upload/f_auto,q_auto,dpr_2,w_48,h_48/Polygon_Po_S_d1987f7e1e" alt="Metamask"></img></a></div>
                    <div className="right-text">
                        <h2><a rel="noreferrer noopener" href="https://polygonscan.com/" target="_blank">Add Polygon Network</a></h2>
                        <p>You must <a rel="noreferrer noopener" href="https://polygonscan.com/" target="_blank">configure the Polygon (MATIC) Blockchain</a> on your Metamask<strong> </strong></p>
                        <p className="del">*(scroll to bottom right of page in the link button)</p>
                    </div>
                </div>

                <div className="video-container">
                    <figure> <video controls src="https://secureservercdn.net/198.71.233.38/n3h.719.myftpupload.com/wp-content/uploads/2022/04/Add-Polygon-Chain-Tutorial.mp4"></video><figcaption>Add Polygon / Matic Chain on your Metamask Wallet </figcaption></figure>
                </div>

                <br />

                <div className="post-container">
                    <div className="left-image"><a href="https://docs.polygon.technology/docs/develop/metamask/config-polygon-on-metamask/" target="_blank" rel="noreferrer noopener"><img src="https://polygon.technology/_nuxt/img/polygon-logo.99647ca.svg" alt="Metamask"></img></a></div>
                    <div className="right-text">
                        <br></br><h2>Click to Add Polygon Blockchain</h2>
                        {/* <p>If Automatically add Polygon button doesn’t work or need clarifications <a rel="noreferrer noopener" href="https://docs.polygon.technology/docs/develop/metamask/config-polygon-on-metamask/" target="_blank">(Manual Instructions)</a></p> */}
                    </div>
                </div>

                <br />

                <div className="post-container">
                    <div className="right-text">
                        <h2><a href="https://global.transak.com/" rel="noopener noreferrer" target="_blank">Buy MATIC (Polygon)</a></h2>
                        <p>Buy some MATIC token on the MATIC/POLYGON network in an equivalent or larger amount than the value that you will invest in our NFTs (allow a little extra for gas fees). </p>

                        <p>Note! This is MATIC on the Polygon blockchain.</p>

                        <p>Each NFT is 700 MATIC (aprox. 0.3 ETH) base price (aprox. 1000 USD).</p>

                        <p>You can use either <a rel="noreferrer noopener" href="https://www.moonpay.com/buy/eth" target="_blank"><strong>Moonpay</strong></a> or <a rel="noreferrer noopener" href="https://global.transak.com/" target="_blank"><strong>Transak</strong></a> as payment gateways to buy MATIC (! on POLYGON/MATIC blockchain) with a credit card.</p>
                    </div>
                </div>
                <div className="post-container">You can also use our OnRamper widget here below and buy 700 MATIC + 5 for Gas, with credit card, then send to your MetaMask and Mint the NFT directly from our Homepage. USD price of MATIC may fluctuate</div>
                <br />
                <div
                    style={{
                        width: "440px",
                        height: "650px",
                        // boxShadow: "0 2px 10px 0 rgba(0, 0, 0, 0.1)",
                        borderRadius: "10px",
                        margin: "auto",
                    }}
                >
                    {/* <OnramperWidget
                        defaultAmount={700}
                        defaultFiat={"USD"}
                        API_KEY={'pk_prod_s0mLpQOWRPkpbZZGX4zyE2nLlt4hVRikf8rsPMres300'}
                        filters={{
                            onlyCryptos: ["MATIC"]
                        }}
                        darkMode={false}
                        color="#a1c245"
                    /> */}
                    <iframe
                        src="https://widget.onramper.com?color=a1c245&apiKey=pk_prod_s0mLpQOWRPkpbZZGX4zyE2nLlt4hVRikf8rsPMres300&defaultCrypto=MATIC&defaultAmount=700&defaultFiat=USD&onlyCryptos=MATIC&supportSell=false"
                        height="660px"
                        width="482px"
                        title="Onramper widget"
                        frameBorder="0"
                        allow="accelerometer; autoplay; camera; gyroscope; payment"
                    // style="box-shadow: 1px 1px 1px 1px rgba(0,0,0,0.2);"
                    >
                        <a href="https://widget.onramper.com" target="_blank" rel="noreferrer noopener">Buy crypto</a>
                    </iframe>
                </div>


                <br />


                {/*
                             
                <div className="post-container">
                    <div className="left-image"><a href="https://opensea.io/GolfDAO-OfficialManagement" target="_blank" rel="noreferrer noopener"><img loading="lazy" src="https://secureservercdn.net/198.71.233.38/n3h.719.myftpupload.com/wp-content/uploads/2022/03/Logomark-White-OpenSea.png" alt="" className="wp-image-368" width="144" height="144"></img></a></div>
                    <div className="left-text">
                        <h2><a href="https://opensea.io/GolfDAO-OfficialManagement" target="_blank" rel="noreferrer noopener"> OpenSea (Matic)</a></h2>
                        <p>To purchase one of our NFTs and join the DAO you must have a <a rel="noreferrer noopener" href="https://metamask.io/download/" target="_blank">METAMASK</a> or similar crypto wallet.</p>
                    </div>
                </div>


                <div className="video-container">
                    <figure ><video controls src="https://secureservercdn.net/198.71.233.38/n3h.719.myftpupload.com/wp-content/uploads/2022/04/Purchase-on-OpenSea-Instructions.mp4"></video><figcaption>Purchase NFT on OpenSea</figcaption></figure>
                    <p className="del"><a rel="noreferrer noopener" href="https://opensea.io/GolfDAO-OfficialManagement" target="_blank"><strong>This function is available </strong> <strong>here</strong></a><strong><a rel="noreferrer noopener" href="https://opensea.io/GolfDAO-OfficialManagement" target="_blank">golfdao.io</a></strong></p>

                    <p>On OpenSea, each GolfDAO Membership NFT is worth 0.3 ETH (Matic ETH) (aprox. 1000 USD). Allow a little extra in your wallet for fees (eg. buy 0.3005 ETH on Matic). </p>

                    <p>Note! This ETH is wrapped ethereum (WETH) on the Polygon blockchain.</p>

                    <p>Each NFT is 0.3 ETH base price on OpenSea, or 700 MATIC directly on our minting page (aprox. 1000 USD).</p>

                    <p className="del">Each Membership NFT will entitle you to an Airdrop of 1000 GolfDAO Tokens. </p>

                    <p className="del">(<em>Note: Important &lt;!&gt;  If you are purchasing the NFT as a resale i.e. not the original mint issued by us… you will not be entitled to DAO GOLF tokens.</em></p>

                    <p className="del"><em>Once all Membership NFTs have been sold in the original mint, you may only acquire GolfDAO GOLF tokens directly from GolfDAO GOLF token owners &lt;!&gt;).</em></p>

                    <p className="del">If you are purchasing directly from our mint at thegolfdao.io or from the first 100 NFT mint on OpenSea, you should be ok.</p> </div>
*/}



            </div>


        );
    }
}

export default Instruction;
