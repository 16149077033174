import React, { createContext, useContext, useEffect, useState } from 'react';

import { ethers } from 'ethers';
import { contractABI, contractAddress, tokenUri, config } from '../utils/constant';
import { toast } from 'react-toastify';

const { ethereum } = window;
const USERCONTEXT = createContext({});

const getProvider = () => {
    const provider = new ethers.providers.Web3Provider(ethereum);
    return provider;
}
const getEthereumContract = () => {
    const provider = getProvider();
    const signer = provider.getSigner();
    const nftContract = new ethers.Contract(contractAddress, contractABI, signer);
    return nftContract;
}

export const USERPROVIDER = ({ children }) => {
    const [currentAccount, setCurrentAccount] = useState(null);
    // const [maxMintAmount, setMaxMintAmmount] = useState(0);
    const [cost, setCost] = useState(0);
    const [nftBalance, setNFTBal] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    if (ethereum) {
        ethereum.on("accountsChanged", (accounts) => {
            checkIfWalletConnected()
            // checkIfOwner();
            // setCurrentAccount(accounts[0]);
            // checkMaxMintAmmount();
            // checkCost();
        });
        ethereum.on("chainChanged", () => {
            window.location.reload();
        });
    }
    const checkIfWalletConnected = async () => {
        try {
            if (!ethereum) {
                return toast.error("Please Install MetaMask")
            }
            // if (checkChainId()) return;
            const accounts = await ethereum.request({ method: 'eth_accounts' });
            // console.log(ethereum.networkVersion);
            if (ethereum.networkVersion === config.NETWORK.ID) {
                if (accounts.length) {
                    setCurrentAccount(accounts[0]);
                    checkCost();
                } else {
                    console.log('No Account Found!')
                }
            }
        } catch (error) {
            toast.error(error.message);
        }
    }
    const connectWallet = async () => {
        try {
            if (!ethereum) {
                return toast.error("Please Install MetaMask")
            }
            if (checkChainId()) return;
            const accounts = await ethereum.request({ method: 'eth_requestAccounts' });
            // checkMaxMintAmmount();
            checkCost();
            console.log(checkCost());
            setCurrentAccount(accounts[0]);
        } catch (error) {
            toast.error(error.message);
        }

    }
    // const checkMaxMintAmmount = async () => {
    //     try {
    //         if (!ethereum) return toast.error("Please Install MetaMask")
    //         const contract = await getEthereumContract();
    //         const maxiMintAmmount = await contract.maxMintAmount();
    //         setMaxMintAmmount(maxiMintAmmount.toNumber());
    //     } catch (error) {
    //         console.log(error.message);
    //     }

    // }
    const claimNFTS = async (amount) => {
        try {
            if (!ethereum) return toast.error("Please Install MetaMask");
            if (!checkChainId()) {
                const contract = await getEthereumContract();
                const nftCost = await contract.cost();
                console.log(contract);
                console.log("Cost is: " + nftCost);
                console.log(Math.random().toString(36).slice(2, 7));
                const contractHash = await contract.safeMint(currentAccount, tokenUri, amount, {
                    value: nftCost
                });
                setIsLoading(true);
                await contractHash.wait();
                setIsLoading(false);
            }
        } catch (error) {
            toast.error(error.data.message);
        }
    }
    const getNFTS = async () => {
        try {
            if (!ethereum) return toast.error("Please Install MetaMask");
            if (!checkChainId()) {
                const contract = await getEthereumContract();
                setIsLoading(true);
                const nftBalance = await contract.balanceOf(currentAccount);
                console.log(contract);
                console.log("You own: " + nftBalance);
                setNFTBal(parseInt(nftBalance) / (10 ** 18));
                // const contractHash = await contract.safeMint(currentAccount, Math.random().toString(36).slice(2, 7), amount, {
                //     value: nftCost
                // });
                setIsLoading(true);
                // await contractHash.wait();
                setIsLoading(false);
            }
        } catch (error) {
            toast.error(error.data.message);
        }
    }
    const checkCost = async () => {
        try {
            if (!ethereum) return toast.error("Please Install MetaMask")
            const contract = await getEthereumContract();
            const nftCost = await contract.cost();
            console.log(parseInt(nftCost) / (10 ** 18));
            setCost(parseInt(nftCost) / (10 ** 18));
        } catch (error) {
            console.log(error.message);
        }
    }
    const checkChainId = () => {
        // eslint-disable-next-line 
        if (ethereum.networkVersion != config.NETWORK.ID) {
            toast.error('Switch Network to Polygon!!!');
            return true;
        } else {
            return false;
        }
    }
    useEffect(() => {
        if (ethereum) {
            checkIfWalletConnected()
        } else {
            toast.error("Please Install MetaMask")
        }
    }, []);
    return (
        <USERCONTEXT.Provider value={{
            connectWallet,
            currentAccount,
            // maxMintAmount,
            claimNFTS,
            cost,
            isLoading,
            getNFTS,
            nftBalance
        }}>
            {children}
        </USERCONTEXT.Provider>
    )
}

export const UserContext = () => useContext(USERCONTEXT);
