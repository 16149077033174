import ABI from './abi.json';

export const contractABI = ABI.abi;
export const contractAddress = '0x8F9643AC01BAC2fC9415659f04DcEe000438a803';
export const tokenUri = 'https://bafybeibsj2kao3qxfnasff56jeyww6kpr7ooylta2cqyujkwx44fo5stiu.ipfs.nftstorage.link/metadata/1.json';

export const shortenAddress = (address) => `${address.slice(0, 5)}...${address.slice(-5)}`;

export const config = {
    NETWORK: {
        "NAME": "Polygon",
        "SYMBOL": "Matic",
        "ID": 137
    },
    WEI_COST: 700,
    maxSupply: 5000
}
