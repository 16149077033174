import React, { useState } from 'react';
import { AiOutlineArrowDown, AiOutlineArrowUp } from 'react-icons/ai';
import { UserContext } from '../context/userContext';
import { Button } from "react-bootstrap";
const Mint = () => {
    const [amount, setAmount] = useState(0);
    const [total, setTotal] = useState(0);
    const {
        // connectWallet,
        currentAccount,
        // maxMintAmount,
        claimNFTS,
        // disconnectWallet,
        cost,
        isLoading
    } = UserContext()
    const increment = () => {
        let amot = amount + 1;
        let tot = amot * 700;
        setAmount(amot);
        setTotal(tot);
    }
    const decrement = () => {
        let amot = amount - 1;
        let tot = amot * 700;
        setAmount(amot);
        setTotal(tot);
    }
    return (
        <>
           {
               currentAccount &&  <fieldset disabled={isLoading} className={isLoading ? 'opacity-5' : ''}>
              
               <div className="wallet" data-aos="zoom-in">
                
                   <fieldset disabled={isLoading} className={isLoading ? 'opacity-5' : ''}>
                       <div className="wallet-wraper">
                           <div className="waller-criteria text-center">
                               <p style={{ marginBottom: '0px' }}>Buy up to 20 NFTS per transaction!</p>
                               <p style={{ marginBottom: '0px' }}>700 MATIC = 1 GolfDAO Membership NFT</p>
                           </div>
                           <div className="incdec">
                               <button className="btn" onClick={() => decrement()} disabled={amount < 1}>
                                   <AiOutlineArrowDown fontSize={20} />
                               </button>
                               <input type="number" step="1" value={amount} disabled />
                               <button className="btn" onClick={() => increment()} disabled={amount >= 20}>
                                   <AiOutlineArrowUp fontSize={20} />
                               </button>
                           </div>
                           <Button className="btn"
                               onClick={() => claimNFTS(amount, cost)}
                               disabled={!currentAccount || !(amount > 0)}>
                               Mint Now {total} MATIC
                           </Button>
                       </div>
                   </fieldset>
                   
               </div>
           </fieldset>
           }
        </>
    )
}
export default Mint;
