import React from "react";
import LottieDisplay from "../component/lottie";

class Roadmap extends React.Component {
    render() {
        return (
            <div>
                <div className="roadmap" id="roadmap">
                    <div className="section-heading" data-aos="fade-up">
                        <LottieDisplay animationPath="/lottie/traveler.json" height={300} width={300} />
                        <h1>
                            Our Roadmap
                        </h1>
                    </div>
                    <div className="timeline">
                        <div className="container left">
                            <div className="date">1st Hole</div>
                            <i className="icon fa fa-home" data-aos="zoom-in"></i>
                            <div className="content">
                                <h2 data-aos="fade-up-right" className="roadmap-title">Driver</h2>
                                <p data-aos="fade-up-right">
                                    Set up DAO to purchase Golf Course in USA. <br /><br />   Invest in Developer Team to create DAO and NFT sale to raise funds. <br /><br />   Invest in Artwork for Marketing Campaign — Membership Card Single NFT.
                                </p>
                            </div>
                        </div>
                        <div className="container right">
                            <div className="date">2nd Hole</div>
                            <i className="icon fa fa-gift" data-aos="zoom-in"></i>
                            <div className="content">
                                <h2 data-aos="zoom-out-up" className="roadmap-title">Woods</h2>
                                <p data-aos="zoom-out-up">
                                    DAO will be managed through member votes on snapshot.org <br /> <br />


                                    LLC is set up in Wyoming to manage business / legal affairs of the DAO.

                                    The DAO is legally compliant with US legislation. We have a strong legal team. <br /> <br />

                                    (Articles of Organization available upon request <a href="mailto:info@thegolfdao.io" target="_blank" rel="noopener noreferrer"> here</a>).

                                </p>
                            </div>
                        </div>
                        <div className="container left">
                            <div className="date">3rd Hole</div>
                            <i className="icon fa fa-user" data-aos="zoom-in"></i>
                            <div className="content">
                                <h2 data-aos="fade-up-right" className="roadmap-title">Irons</h2>
                                <p data-aos="fade-up-right">
                                    We are raising $10 million USD. Each NFT will allow for one vote in the DAO. More details available on our Telegram. <br /> <br />
                                    DAO Reserve is a backup of funds we will use according to our needs determined by the community.


                                </p>
                            </div>
                        </div>
                        <div className="container right">
                            <div className="date">4th Hole</div>
                            <i className="icon fa fa-running" data-aos="zoom-in"></i>
                            <div className="content">
                                <h2 data-aos="zoom-out-up" className="roadmap-title"><br></br><br></br>Wedge</h2>
                                <p data-aos="zoom-out-up">
                                    Owners of standard NFTs will have several perks: <br /> <br />


                                    Free golf access at Golf Club. <br /> <br />

                                    Free rentals at Golf Club. <br /> <br />

                                    Discounts on food and beverage inside Golf Club 10% off.

                                    Discounts on the golf pro shop inside Golf Club 10% off. <br /> <br />

                                    AMAs with Golf personalities on Discord and other platforms. One-to-one virtual sessions with golf pros where they comment on your swing. <br /> <br />

                                    Free golf club / golf accessories giveaways. Golfing trips and access to clubs worldwide. <br /> <br />





                                </p>
                            </div>
                        </div>
                        <div className="container left">
                            <div className="date">5th Hole</div>
                            <i className="icon fa fa-cog" data-aos="zoom-in"></i>
                            <div className="content">
                                <h2 data-aos="fade-up-right" className="roadmap-title">Putter</h2>
                                <p data-aos="fade-up-right">
                                    GolfDAO promo through social networks (Twitter, Discord, IG, FB, LinkedIn, Reddit, Medium...). Comprehensive marketing campaigns. <br /> <br />
                                    PR on Crypto News Sites (Apple News and main crypto sites).  <br /> <br />
                                    Invest in Artwork for Marketing Campaign - GolfBYRDS — Profile Picture Fun Artworks with different traits. <br /> <br />

                                    DAO NFTs for certain key influencers in Golf, Sports, Marketing and
                                    Crypto spaces in return for promoting our project.

                                </p>
                            </div>
                        </div>
                        {/* <div className="container right">
                            <div className="date">01 Jan</div>
                            <i className="icon fa fa-certificate" data-aos="zoom-in"></i>
                            <div className="content">
                                <h2 data-aos="zoom-out-up" className="roadmap-title">Lorem ipsum dolor sit amet</h2>
                                <p data-aos="zoom-out-up">
                                    Lorem ipsum dolor sit amet elit. Aliquam odio dolor, id luctus erat sagittis non. Ut blandit
                                    semper pretium.
                                </p>
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>
        );
    }
}

export default Roadmap;
