import React from "react";
import LottieDisplay from "../component/lottie";

class ThePlan extends React.Component {
    render() {
        return (

            // <!-- footer section starts from here -->
            <div className="plan">
                <div className="section-heading" data-aos="fade-up">
            {/*   <LottieDisplay animationPath="/lottie/golfing.json" height={300} width={300} />  */}

                <img src="assets/images/golf-ball.webp" width={400} data-aos="zoom-in" alt=""></img>

                    <h1>
                        The Plan
                    </h1>
                </div>

                <div className="section-text" data-aos="fade-up">
                    <p className="p-gap"><strong>1)</strong> Sell general membership NFTs (public) + GolfBYRDS NFTs (accredited investors only). Regular membership NFT is valued at 700 MATIC (Approx. $1000 USD). GolfBYRDS value dependent on rarity.</p>

                    <p className="p-gap"> <strong>2)</strong> Each membership NFT will grant the member 1 vote in our DAO decision making process managed on snapshot.org/#/golf-dao.eth   . GolfBYRDS get profits from Golf course and other business endeavors.</p>

                    
                    <p className="p-gap"> <strong>3)</strong> The DAO is buying a physical Golf Course in the USA.</p>
                <br></br>
                </div>
                
                <div className="section-heading" data-aos="fade-up">
                <LottieDisplay animationPath="/lottie/vote.json" height={200} width={200} />
                    <h1>
                    Vote Now on Snapshot.eth
                    </h1>
                    <p><a href="https://snapshot.org/#/golf-dao.eth" target="_blank" rel="noopener noreferrer"><img src="assets/images/snapshot-logo.png" alt="" width="150"></img></a></p>
                </div>
                <div className="headings">

                    <p className="p-gap2">DAO Treasury Contract Address:</p>
                    <p>0x0d28cE6c997363Fb6A76f
                        0AF9BAC215e6D488623</p>


                    <p className="p-gap2">NFT Contract Address:</p>
                        <a href="https://polygonscan.com/address/0x8F9643AC01BAC2fC9415659f04DcEe000438a803" target="_blank" rel="noopener noreferrer">
                            <p>0x8F9643AC01BAC2fC9415
                                659f04DcEe000438a803</p></a>
                </div>
                <br></br>
            </div>
            // <!-- footer section ends here -->

        );
    }
}

export default ThePlan;
