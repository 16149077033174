import React from "react";
import { Image } from "react-bootstrap";
import { SocialIcon } from "react-social-icons";


class Footer extends React.Component {

    render() {
        return (

            // <!-- footer section starts from here -->
            <div className="footer">
                <div className="section-heading" data-aos="fade-up">
                    <SocialIcon fgColor='#a1c245' bgColor='#ffffff' url="https://discord.gg/sxp3QxpnTU" />
                    <SocialIcon fgColor='#a1c245' bgColor='#ffffff' url="https://twitter.com/GolfDAO1" />

                    <SocialIcon fgColor='#a1c245' bgColor='#ffffff' url="https://www.instagram.com/golfdao1/" />
                    <SocialIcon fgColor='#a1c245' bgColor='#ffffff' url="https://www.linkedin.com/in/golfdao/" />
                    <SocialIcon fgColor='#a1c245' bgColor='#ffffff' url="https://t.me/GolfDAO1" />

                </div>
                <br></br>


                {/*<center><iframe title="beehiiv" src="https://embeds.beehiiv.com/8d11dab4-9a05-4266-9a57-e1d1f2f5e7d3" data-test-id="beehiiv-embed" width="100%" height="320" frameborder="0" scrolling="no" style={{borderRadius: '4px', border: '2px solid #a1c245', margin: '0', backgroundColor: '#a1c245',}}></iframe></center> */}

                {/* <MailchimpSubscribe url="//thegolfdao.us9.list-manage.com/subscribe/post?u=213452cca726c55429fb85e60&amp;id=5b073180b7" /> */}
                <br></br><br />

                <p>
                    &copy; Copyright GolfDAO | All Rights Reserved | 2022
                </p>
            </div>
            // <!-- footer section ends here -->
        );
    }
}

export default Footer;
