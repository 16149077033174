import React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import LazyHero from "react-lazy-hero";


class ParallaxThree extends React.Component {
    render() {
        return (
            <div>



                <div className="section-heading" data-aos="fade-up">
                    <h1>...</h1>
                </div>
                <LazyHero imageSrc="assets/images/travel_happy_hourgdao.webp" opacity='0' minHeight="333px" parallaxOffset='99'>
                </LazyHero>
                




            </div>
        );
    }
}

export default ParallaxThree;
